<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-card :title="heading">

                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body">
                            {{error_message}}
                        </div>
                    </b-alert>
                
                    <b-form @submit.prevent="formSubmit">
                        <b-row>
                            <b-col md="12">
                                <b-row>
                                    <b-col md="12">
                                        <b-form-group label="Project Site" class="required">
                                            <!-- <b-form-select v-model="temp_site">
                                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                                <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                                            </b-form-select> -->
                                            <v-select
                                                v-model="temp_site"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                label="site_name"
                                                :options="sites"
                                                placeholder="Select"
                                                :close-on-select=false
                                                class="multiselect_muliple_options"
                            :clear-on-select=false
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <div class="fsmTable table-responsive" style="margin-bottom: 0px !important;">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="width: 95%">Important Point <span class="text-danger">*</span></th>
                                                <th scope="col" style="width: 5%"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(fm, index) in form" :key="index">
                                                <td>
                                                    <b-form-input placeholder="3 to 200 characters" v-model="fm.question_name" />
                                                </td>
                                                <td class="text-center">
                                                    <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <!-- <b-row>

                                    <b-col md="10">Important Point<span class="text-danger">*</span></b-col>
                                    <b-col md="2"></b-col>
                                </b-row>

                                <b-row v-for="(fm, index) in form" :key="index" class="mt-1">
                                    <b-col md="11">
                                        <b-row> -->
                                            <!-- <b-col md="6">
                                                <b-form-select v-model="fm.site">
                                                    <b-form-select-option value="">Select</b-form-select-option>
                                                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                                                </b-form-select>
                                            </b-col> -->
                                            <!-- <b-col md="12">
                                                <b-form-input placeholder="3 to 200 characters" v-model="fm.question_name" />
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <b-col md="1" class="text-center">
                                        <b-button variant="danger" @click="removeRow(index);">-</b-button>
                                    </b-col>
                                </b-row> -->

                                <b-row class="mb-1">
                                    <b-col md="12" class="text-center">
                                        <hr>
                                        <b-button variant="primary" @click="addRow">Add More</b-button>
                                    </b-col>
                                </b-row>
                                            
                                <b-row>
                                    <b-col>
                                        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1">
                                            Submit
                                        </b-button>

                                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.push({ name:'questions' })">
                                            Cancel
                                        </b-button>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import {
        BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile, BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import vSelect from 'vue-select';

    export default {
        components: {
            BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile, BBreadcrumb
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,

                sites:[],

                temp_site: '',

                form :[
                    {
                        question_name : '',
                        site          : '',
                    }
                ],

                // Page Labels
                heading : 'Add Important Point',
                api     : '/api/add-important-point',
            }
        },

        methods : {
            formSubmit(){
                var data = [];

                this.form.forEach(ele => {
                    if(this.temp_site){
                        this.temp_site.forEach(site => {
                            data.push({
                                question_name : ele.question_name,
                                site          : site._id
                            });
                        });
                    }
                });

                return this.$store.dispatch(POST_API, {
                    data:{
                        items : data,
                    },
                    api : this.api
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;

                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;

                        this.successAlert().then((result) => {
                            // this.$router.go(-1);
                            this.$router.push({ name:'questions' })
                        });
                    }
                });
            },

            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.sites = data;
                        return this.sites;
                    }
                });
            },

            addRow(){
                this.form.push({
                    question_name : '',
                    site          : '',
                })
            },

            removeRow(index) {
                if(this.form.length > 1){
                    this.form.splice(index, 1);
                }
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:{name:'custom-forms'},
                text: 'Custom Forms',
              },{
                to:{name:'create-toolbox-meeting'},
                text:'Add Toolbox Meeting'
              },{
                to:{name:'questions'},
                text:'Important Points'
              },{
                to:null,
                text:'Add Important Point',
                active:true
              }];
              return item;
            }
        },
        mounted(){
            this.allSites();
        }
    }
</script>
